<script>
export default {
  data() {
    return {
      passwordField: null,
      passwordVisible: false
    };
  },
  mounted() {
    this.passwordField = $("input[type='password']");
  },
  methods: {
    toggleVisibility() {
      if (this.passwordVisible) {
        $(this.passwordField).attr('type', 'password');
      } else {
        $(this.passwordField).attr('type', 'text');
      }

      this.passwordVisible = !this.passwordVisible;
    }
  }
};
</script>

<template>
  <div
    class="input-group-append d-flex justify-content-center align-items-center bg-primary border cursor-pointer"
    @click="toggleVisibility()"
    password-visibility-switch-component
  >
    <i class="fa-solid" :class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
  </div>
</template>

<style lang="scss" scoped>
[password-visibility-switch-component] {
  width: 3rem;
}
</style>