<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";
import anime from 'animejs/lib/anime.es.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  data() {
    return {
      paddingYDefault: 4
    };
  },
  methods: {
    getAnswerClasses(answer) {
      if (this.isUserAnswer(answer.$id)) {
        return 'selected';
      }
    },
    isUserAnswer(answerId) {
      return this.getUserInteractionAnswerId(this.containerContent, this.content.interaction) == answerId;
    },
    handleAnswerClick(answerId) {
      this.updateTriviaInteraction(this.containerContent, this.content, this.content.interaction, answerId);
      this.answerClickAnimation(answerId);
    },
    answerClickAnimation(answerId) {
      const answer = `answer-${answerId}`;

      anime
        .timeline({ targets: this.$refs[answer] })
        .add({
          scale: 0.95,
          opacity: 0.8,
          easing: 'linear',
          duration: 100
        })
        .add({
          scale: 1,
          opacity: 1,
          easing: 'easeOutElastic',
          duration: 800
        })
    },
    answerFadeInAnimation(el) {
      const delay = (el.dataset.index * 80) + 200;

      anime({
        targets: el,
        opacity: [0, 1],
        translateY: ['+2rem', '0rem'],
        easing: 'easeOutCubic',
        duration: 800,
        delay: delay
      })
    }
  },
  computed: {
    containerClasses() {
      const classes = [];
      if (this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }
      return classes;
    },
    contentClasses() {
      const classes = [];
      if (!this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }
      return classes;
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.getContentResponsiveImageURL("background_image") : ""
      };
    },
    contentStyles() {
      return {
        "background-image": this.propagateBackground ? "" : this.getContentResponsiveImageURL("background_image")
      };
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    themedButton() {
      if (this.content.button_class) {
        return this.content.button_class;
      } else {
        return (this.themeType == "light") ? "btn-outline-dark" : "btn-outline-light";
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="[contentType, containerClasses]" v-if="isContentVisibleToUser()" :style="[theme, containerStyles]" interaction-trivia-component>
    <div class="container" :class="contentClasses" :style="contentStyles">
      <div class="row justify-content-center px-3">
        <div class="col-12 col-lg-8 text-center px-0 mb-3">
          <p class="mb-3" v-if="content.category">{{content.category}}</p>
          <h2 class="h1 mb-3">{{content.question}}</h2>
        </div>
      </div>
      <transition-group tag="div" class="row justify-content-center " :css="false" @enter="answerFadeInAnimation" appear>
        <div
          :ref="`answer-${answer.$id}`"
          v-for="(answer, index) in content.answers"
          class="answer col-12 col-md-6 col-lg-3 p-3"
          :class="getAnswerClasses(answer)"
          :key="answer.$id"
          :data-index="index"
        >
          <div class="d-flex flex-md-column align-items-center cursor-pointer" @click="handleAnswerClick(answer.$id)">
            <img v-if="answer.image" :src="answer.image.url" class="answer-image mr-3 mr-md-0 mb-md-3">
            <p class="text-left text-md-center mb-0">{{answer.text}}</p>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-trivia-component] {

  .answer {
    &.selected {
      background-color: rgba($white, 0.2);
    }

    &-image {
      height: 8rem;

      @include media-breakpoint-down(sm) {
        height: 4rem;
      }
    }
  }

  .btn {
    border-radius: 0.5rem;
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    position: absolute;

    .fal {
      line-height: 3rem;
      font-size: 1.5rem;
    }
  }

}
</style>
