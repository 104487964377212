<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      isVisible: false
    };
  },
  mounted() {
    Fandom.onEvent('show-instantwin-banner', () => {
      this.isVisible = true;
    });
  },
  computed: {
    tickets() {
      return this.pageModel.user?.reward_name_to_counter?.ticket || 0;
    },
    instantWinUrl() {
      const instanwinUrl = this.pageModel.layout.content.instant_win_url?.url;
      return instanwinUrl ? this.applyContextToUrl(instanwinUrl) : 'javascript:void(0)';
    }
  }
};
</script>

<template>
  <transition enter-active-class="animated fadeInUp faster">
    <div v-if="isVisible" class="fixed-bottom bg-white shadow py-4" instant-win-banner-component>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md d-flex justify-content-center justify-content-md-start align-items-center">
                <span class="h3 text-secondary mb-0">{{ft('globals.instant_win_banner.title', {tickets})}}</span>
              </div>
              <div class="col-12 col-md-auto d-flex justify-content-center justify-content-md-end align-items-center mt-3 mt-md-0">
                <a :href="instantWinUrl" class="btn btn-secondary pulse-animation">
                  <i class="fa-solid fa-star mr-2"></i>
                  <span>{{ft('globals.instant_win_banner.button')}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
[instant-win-banner-component] {
  .pulse-animation {
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
}
</style>