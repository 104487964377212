<template>
  <div 
    v-easyadmin="easyadminId" 
    class="themed-item" 
    :class="[contentType, paddingY]" 
    :style="[contentStyle, theme]" 
    list-carousel-component
    :id="anchorId"
  >
    <div :class="containerClass">
      <slot name="carousel-header"></slot>
      <slot name="carousel-body">
        <div class="carousel-body position-relative">
          <div class="row">
            <div class="col-12">
              <div class="flickity-carousel" ref="flickity-carousel">
                <div
                  class="flickity-carousel-item justify-content-center"
                  v-for="(item, index) in content.items" 
                  :key="`carousel-slide-${index}`"
                >
                  <component 
                    :is="item.layout"
                    :content="item"
                    :opt-container-content="containerContent"
                    :reset="resetAnswer"
                    :current-interaction-id="currentInteractionId"
                    @interaction-handler="$emit('interaction-handler', arguments[0])"
                    @user-interaction-updated="(args) => $emit('user-interaction-updated', args)"
                    @set-custom-background-image="setCustomBackgroundImage"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
      <slot name="carousel-footer"></slot>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from 'src/modules/interactions_module.js'
import Flickity from "flickity";

const DEFAULT_CONFIG = {
  draggable: "> 1",
  prevNextButtons: true,
  contain: true,
  pageDots: true,
  cellAlign: "center",
  wrapAround: "true",
  groupCells: true,
  adaptiveHeight: true,
};

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  props: {
    resetAnswer: {
      type: Boolean,
      default: false
    },
    slideConfig: {
      type: Object,
      default: () => {return {}}
    },
    containerClass: {
      type: String,
      default: "container"
    },
    currentInteractionId: {
      type: Number,
      default: -1
    }
  },
  data: function () {
    return { 
      slider: null,
      customBackgroundImage: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Vue.nextTick(() => {
        this.mountCarousel();
      });
    },
    mountCarousel() {
      this.slider = new Flickity(this.$refs["flickity-carousel"], {...DEFAULT_CONFIG, ...this.slideConfig});
      this.slider.on("ready", this.$emit("slider-ready", this.slider));
      this.slider.on("change", (index) => this.$emit("slider-change", index));
      this.slider.on("settle", (index) => {this.slider.resize(); this.$emit("slider-settle", index)});
    },
    setCustomBackgroundImage(value) {
      this.customBackgroundImage = value;
    }
  },
  computed: {
    contentStyle() {
      const backgroundImage = this.customBackgroundImage?.url || this.content.background_image?.url;

      return {
        "background-image": `url('${backgroundImage}'`
      };
    }
  }
};
</script>

<style lang="scss">
[list-carousel-component] {
  background-size: 100% auto;

  .carousel-body ::v-deep .container {
    padding-left: 0;
    padding-right: 0;
  }
 
  .flickity {
    &-viewport, &-carousel {
      &:focus {
        outline: none !important;
      }
    }
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;

    &.next {
      right: -65px;
    }

    &.previous {
      left: -65px;
    }

    @include media-breakpoint-down(xs) {
      border: none;
    }

    &:disabled {
      display: none;
    }
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    width: 100%;
    overflow: hidden;

    > div {
      position: relative;
    }
  }
}
</style>
