<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mqLess } from 'src/modules/viewport_module';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      sidebar: false
    };
  },
  methods: {
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs['sidebar']);
        this.sidebar = true;
      }
    }
  },
  computed: {
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    logoAlt() {
      return this.pageModel.seo.title || '';
    },
    tickets() {
      return this.pageModel.user?.reward_name_to_counter?.ticket || 0;
    },
    isMobile() {
      return mqLess('lg');
    },
    hasItems() {
      return this.content.items.length > 0;
    },
    signInUrl() {
      return this.applyContextToUrl('/users/sign_up');
    },
    instantWinUrl() {
      const instanwinUrl = this.pageModel.layout.content.instant_win_url?.url;
      return instanwinUrl ? this.applyContextToUrl(instanwinUrl) : 'javascript:void(0)';
    }
  }
};
</script>

<template>
  <div class="sticky-top">
    <nav id="main-navbar" class="navbar navbar-expand-lg bg-white text-primary justify-content-between px-4 py-3" navbar-component>
      <div class="d-flex">
        <div v-if="isMobile && hasItems" class="d-flex justify-content-center align-items-center text-primary cursor-pointer mr-4" @click="toggleSidebar">
          <i class="fal fa-bars fa-2x"></i>
        </div>
        <a class="d-flex align-items-center" :href="applyContextToUrl('/')">
          <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <template v-if="!isMobile && hasItems">
          <div class="d-flex align-items-center px-3" v-for="item in content.items">
            <a class="navbar-url text-primary text-uppercase font-weight-bold text-decoration-none" :href="item.url.url" :target="getTarget(item.url)">
              {{item.url.title}}
            </a>
          </div>
          <div class="d-flex align-items-center px-3" v-if="isUserRegistered">
            <a class="navbar-url text-primary text-uppercase font-weight-bold text-decoration-none" href="/users/sign_out">
                  {{ft('globals.login.logout')}}</a>
          </div>
        </template>
        <div class="d-flex align-items-center bg-primary rounded-pill px-3 py-1 ml-3">
          <a class="navbar-url text-white text-uppercase text-decoration-none" :href="isUserRegistered ? instantWinUrl : signInUrl">
            <template v-if="isUserRegistered">
              <span>+{{tickets}}</span>
              <i class="fa-solid fa-star"></i>
            </template>
            <template v-else>{{ft('globals.login.login')}}</template>
          </a>
        </div>
      </div>
    </nav>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed" @click="toggleSidebar" v-if="sidebar && isMobile" sidebar-layer></div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div class="position-fixed bg-white border-right" ref="sidebar" v-if="sidebar && isMobile" sidebar>
        <div class="close-icon text-primary position-absolute p-4 cursor-pointer">
          <i class="fal fa-times fa-2x" @click="toggleSidebar"></i>
        </div>
        <div class="d-flex flex-column h-100">
          <div class="flex-grow-1 px-4">
            <img :src="content.logo.url" class="sidebar-logo" :alt="logoAlt"/>
            <div class="py-4">
              <div class="border-bottom py-3" v-for="item in content.items">
                <a class="navbar-url text-primary text-uppercase font-weight-bold text-decoration-none" :href="item.url.url" :target="getTarget(item.url)">
                  {{item.url.title}}
                </a>
              </div>
              <div class="border-bottom py-3">
                <a class="navbar-url text-primary text-uppercase font-weight-bold text-decoration-none" v-if="!isUserRegistered" href="/users/sign_up">
                  {{ft('globals.login.sign_up')}}
                </a>
                <a class="navbar-url text-primary text-uppercase font-weight-bold text-decoration-none" v-if="isUserRegistered" href="/users/sign_out">
                  {{ft('globals.login.logout')}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>

[navbar-component] {
  min-height: $header-height-sm;
  transition: all $animation-time-fast linear;

  @include media-breakpoint-up(md) {
    min-height: $header-height;
  }

  .navbar-logo {
    height: calc(#{$header-height-sm} - 2.6rem);

    @include media-breakpoint-up(md) {
      height: calc(#{$header-height} - 2rem);
    }
  }
}

[navbar-component], [sidebar] {
  .navbar-url {
    transition: color .25s;
    white-space: nowrap;
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  padding-top: 1.5rem;
  overflow-y: auto;

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    height: 1.575rem;
  }

  .border-bottom,
  &.border-right {
    border-color: $gray-100 !important;
  }
}
</style>
