<script>
import { interactionMixin } from 'src/modules/interactions_module.js';
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';

export default {
  component: { Intersect },
  mixins: [interactionMixin, fandomBasicMixin, fandomExtendedMixin],
  props: {
    showRestartBtn: {
      type: Boolean,
      default: false
    },
    currentInteractionId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      contentResult: null,
      completed: false,
      isLoading: false
    };
  },
  methods: {
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    updateInteractionSuccessCallback(data) {
      this.contentResult = data.content_result;
      
      if (this.isLoading) {
        setTimeout(() => {
          this.completed = true;
          this.isLoading = false;
          this.resize();

          if (this.content.background_image) {
            this.$emit("set-custom-background-image", this.content.background_image);
          }

          Fandom.emit("show-instantwin-banner");
        }, 3000);
      } else {
        this.completed = true;
      }

      this.resize();
    },
    handleError(data) {
      if (data.error) {
        setTimeout(() => {
          this.isLoading = false;
          this.completed = false;
          this.resize();
        }, 3000);
      }
    },
    resize() {
      Vue.nextTick(() => window.dispatchEvent(new Event('resize')));
    },
    checkResult() {
      if (this.contentResult && this.completed) {
        return;
      }
      this.isLoading = true;
      this.updateTestInteraction(this.containerContent, this.content, this.content.interaction, null, this.updateInteractionSuccessCallback, this.handleError);
    }
  },
  computed: {
    loading() {
      return this.content.loading;
    }
  },
  watch: {
    currentInteractionId: {
      handler(value) {
        if (value == this.content.interaction.id) {
          this.checkResult();
        }
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType]" v-if="isContentVisibleToUser()" test-interaction-component>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" v-if="contentResult && !isLoading">
      <div v-if="completed" class="justify-content-center d-flex align-items-center flex-column h-100">
        <component :is="contentResult.layout" :content="contentResult" :opt-container-content="containerContent" class="w-100"></component>
      </div>
    </transition>
    <div class="row loading-container justify-content-center align-items-center flex-column text-center" v-else>
      <div v-if="isLoading" class="col-12 text-center">
        <i class="fa-flip fa-light fa-island-tropical"></i>
      </div>
      <div v-else-if="!completed" class="col-12">
        <component :class="loading.title_class" :is="loading.title_tag || 'h3'" :style="{'color': loading.text_color}" title>
          {{ft("globals.test_interaction.incomplete_title")}}
        </component>
        <component class="pt-3" :is="loading.description_tag || 'p'" :class="loading.description_class" :style="{'color': `${loading.text_color}`}" description>
          {{ft("globals.test_interaction.incomplete_message")}}
        </component>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[test-interaction-component] {
  .loading-container {
    i {
      font-size: 15rem;
    }
  }
}
</style>
