<template>
  <list-carousel
    v-if="currentIndex >= 0"
    class="min-fullscreen d-flex flex-column justify-content-center"
    :content="content"
    :opt-container-content="optContainerContent"
    :reset-answer="resetAnswer"
    :slide-config="slideConfig"
    :current-interaction-id="currentInteractionId"
    @slider-ready="handleSliderReady"
    @slider-change="handleSliderChange"
    @slider-settle="handleSliderSettle"
    @user-interaction-updated="handleUserInteraction"
    @interaction-handler="handleInteraction"
    list-carousel-interactive-component
  >
    <template #carousel-header>
      <div v-show="answerCount != questionCount" class="mx-3 pt-4 pt-md-5">
        <p class="text-white text-center mb-0">
          {{ ft("globals.list_carousel_interactive.question_of", { currentQuestion: answerCount + 1, questionCount }) }}
        </p>
      </div>
    </template>
    <template #carousel-footer>
      <div v-show="answerCount != questionCount" class="mx-3 pb-4">
        <div class="d-flex align-items-center position-relative">
          <div class="progress-dots d-flex align-items-center justify-content-between w-100">
            <div
              v-for="(_, index) in questionCount"
              class="progress-dot rounded-circle border border-white"
              :class="{'bg-secondary': answerCount >= index}"
            ></div>
          </div>
          <div class="progress-line position-absolute w-100 bg-white"></div>
        </div>
      </div>
    </template>
  </list-carousel>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from 'src/modules/interactions_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function () {
    return { 
      slider: null,
      interactionHandlerTimeout: null,
      resetAnswer: false,
      currentIndex: -1,
      answerCount: 0,
      firstUnansweredQuestion: -1,
      paddingYDefault: 4,
      slideConfig: {
        pageDots: false,
        prevNextButtons: false,
        draggable: false
      }
    };
  },
  mounted() {
    this.initAnswerCounter();
  },
  methods: {
    initAnswerCounter() {
      const itemInteractionIDs = this.interactions
        .filter(item => typeof item.interaction !== 'undefined')
        .map(item => `${item.interaction.id}`);

      const contentUserInfo = this.getContentUserInfo(this.containerContent);

      itemInteractionIDs.forEach((id) => {
        if (id in contentUserInfo.id_to_interaction) {
          const interaction = contentUserInfo.id_to_interaction[id];

          if (interaction.interaction_type != "Test") {
            if (this.exists(interaction.user_interaction)) {
              this.answerCount++;
            } else if (this.firstUnansweredQuestion === -1) {
              this.firstUnansweredQuestion = id;
            }
          }
        }
      });

      if (this.firstUnansweredQuestion !== -1) {
        this.currentIndex = this.interactions.findIndex( item => {
          return get(item, "interaction.id", false) == this.firstUnansweredQuestion;
        })
      } else {
        this.currentIndex = this.interactions.length-1;
      }
    },
    handleSliderReady(slider) {
      this.slider = slider;
      this.slider.select(this.currentIndex, false, true);

      if (this.currentIndex > 0) {
        // Intersect workaround 
        const tmp = document.documentElement.scrollTop; 
        document.documentElement.scrollTop = 0; 
        setTimeout(() => {document.documentElement.scrollTop = tmp }, 0);
      }
    },
    handleSliderChange(index) {
      clearTimeout(this.interactionHandlerTimeout);
      this.interactionHandlerTimeout = null;
      if (index > 0) {
        this.currentIndex = index;
      }
    },
    handleSliderSettle() {
      this.slider.resize();
      $(".carousel-body").scrollTop = 0;
    },
    handleUserInteraction(data) {
      if (data.interaction.type != "Test") {
        this.interactionHandlerTimeout = setTimeout(() => {
          this.answerCount++;
          this.interactionHandlerTimeout = null;
          this.slider.next();
        }, 1000);
      }
    },
    handleInteraction(data) {
      if (data.action == "reset") {
        this.answerCount = 0;
        this.resetAnswer = true;
        this.currentIndex = 0;
        Vue.nextTick(() => {
          this.slider.select(0, false, true);
          this.resetAnswer = false;
        })
      }
    }
  },
  computed: {
    interactions() {
      return this.content.items;
    },
    currentInteractionId() {
      return this.interactions[this.currentIndex].interaction.id;
    },
    questionCount() {
      return this.interactions.reduce((acc, item) => {
        return acc += item.interaction && item.interaction.type != "Test" ? 1 : 0
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.progress {
  &-line {
    height: 1px;
  }

  &-dots {
    z-index: 100;
  }

  &-dot {
    height: 0.875rem;
    width: 0.875rem;
    background-color: $white;
  }
}

::v-deep {
  .flickity-prev-next-button { display: none; }

  .flickity-carousel-item { 
    min-width: 300px; 
    padding-top: 1.5rem !important;  
    padding-bottom: 1.5rem !important;
  }
}

.carousel-header {
  position: relative;

  .progress {
    height: 6px;
    width: 40%;
  }
}
</style>