<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  computed: {
    logoAlt() {
      return this.pageModel.seo.title || '';
    }
  }
};
</script>

<template>
  <div footer-component>
    <component v-if="content.stripes" :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="footer-navbar text-white">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row py-4 py-md-5">
              <div class="col-12 col-md-3 d-flex justify-content-center justify-content-md-start align-items-center mb-4 mb-md-0">
                <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
              </div>
              <div class="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-center align-items-center small mb-4 mb-md-0">
                <div class="d-flex align-items-center px-3" v-for="item in content.navbar_bottom">
                  <a class="navbar-url text-white mb-1 mb-md-0 text-decoration-none" :href="item.url.url" :target="getTarget(item.url)">
                    {{item.url.title}}
                  </a>
                </div>
                <div class="d-flex align-items-center px-3">
                  <a href='#' class='navbar-url text-white mb-1 mb-md-0 text-decoration-none iubenda-cs-preferences-link'>Cookie policy</a>
                </div>
              </div>
              <div class="col-12 col-md-3 d-flex justify-content-center justify-content-md-end align-items-center">
                <div class="nav-item mx-2" v-for="icon in content.navbar_social">
                  <a class="navbar-url text-white text-decoration-none" :href="icon.url.url" :target="getTarget(icon.url)">
                    <i class="fa-2xl" :class="icon.icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disclaimer">
      <div class="container">
        <div class="justify-content-center py-4 disclaimer-text text-center small">
          <div v-html="content.disclaimer"></div>
          <p class="mb-0 mt-4">
            This site is protected by reCAPTCHA and the Google 
            <a href="https://policies.google.com/privacy" target="_blank" class="font-weight-normal">Privacy Policy</a> and 
            <a href="https://policies.google.com/terms" target="_blank" class="font-weight-normal">Terms of Service</a> apply.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[footer-component] {
  .footer-navbar {
    background-color: $blu;
  }

  .small {
    font-size: 0.875rem;
  }

  .disclaimer {
    background-color: $dark-blu;

    &-text {
      color: rgba($white, 0.3);

      > p:last-child {
        margin-bottom: 0;
      }
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }

  .navbar-url {
    transition: color .25s;
    white-space: nowrap;
  }

  .navbar-logo {
    height: 2.5rem;
  }
}
</style>
