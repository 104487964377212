<script>

export default {
  data() {
    return {
      content: {}
    };
  },
  mounted() {
    Fandom.onEvent('modal-content-required', (content) => {
      this.content = content;
      Vue.nextTick(() => {
        $(this.$refs['modal-content']).modal('show');  
      });
    });
  }
}
</script>

<template>
  <div ref="modal-content" class="modal fade" tabindex="-1" role="dialog" modal-content-component>
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <a href="javascript: void(0)" class="modal-content-close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <img class="w-100" v-if="content.image" :src="content.image.url">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal-content-close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    font-size: 2rem;
    color: $white;
    text-decoration: none;
  }
</style>